import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useCityPrefix } from '@/context/CityPrefixContext';
import s from '@/styles/partials/TopCategory.module.scss';
import s2 from '@/styles/main/Buttons.module.scss';
import i18n from '@/lib/i18n';
import { useCategories } from '@/modules/categories';
import TopCategoryBtn from '../shared/categories/TopCategoryBtn';
import { VerifiedCompaniesLink } from './verified-companies-link.component';


const API_URL = process.env.NEXT_PUBLIC_API_URL;

export default function TopCategory() {
  const [_topCategories, set_topCategories] = useState([]);
  const { locale } = useRouter();
  const { cityPrefix } = useCityPrefix();
  const { getCategoryByCode } = useCategories();

  const topCategories = useMemo(
    () => _topCategories.map((item) => getCategoryByCode(item.codename)),
    [getCategoryByCode, _topCategories],
  );

  useEffect(() => {
    fetch(`${API_URL}/api/admin_panel/categories/top/list/?size=3`)
      .then((response) => response.json())
      .then((data) => {
        set_topCategories(data);
      });
  }, []);

  return (
    <section className={s.category_block}>
      <VerifiedCompaniesLink />
      <Link
        href={`/${cityPrefix}/posters`}
        className={clsx(s2.events_link, s2.top_category)}
      >
        <img src="/assets/icons/type=event.svg" alt="Events" />
        <span>{i18n.events123[locale]}</span>
      </Link>
      {topCategories.map((category) => (
        <TopCategoryBtn
          link={category.pathname}
          key={category.id}
          icons={category.icon}
          title={category.title}
        />
      ))}
    </section>
  );
}
