import React from 'react';
import s from '@/styles/main/Buttons.module.scss';
import Link from 'next/link';


export default function TopCategoryBtn({ icons, title, link }) {
  return (
    <Link href={link}>
      <button className={s.top_category}>
        <img src={icons} alt={title} />
        <span>{title}</span>
      </button>
    </Link>
  );
}
