import React from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import icon from './verified-companies-link.icon.png';
import styles from './verified-company-link.module.scss';


const link = process.env.NEXT_PUBLIC_VERIFIED_COMPANIES_LINK || '/';
const target = process.env.NEXT_PUBLIC_VERIFIED_COMPANIES_LINK_TARGET;

export const VerifiedCompaniesLink = () => {
  const { t } = useTranslation('index');
  const title = t('verifiedCompanies');

  return (
    <Link href={link} className={styles.link} target={target}>
      <img src={icon.src} alt={title} />
      {title}
    </Link>
  );
};
