import React, { Fragment, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useGlobalData } from '@/context/GlobalContext';
import i18n from '@/lib/i18n';
import { NextSeo } from 'next-seo';
import { useInfiniteListRef } from '@/modules/shared/hooks';
import { useAdsInfiniteListQuery } from '@/modules/ads/queries';
import { useHomeCity } from '@/modules/locations/hooks';
import { AdLink } from '@/modules/ads/components';
import { AdCard } from '@/modules/ads';
import Loadingmain from '../skeleton/loadingmain';
import s from './AdvList.module.scss';

export default function AdvList() {
  const { homeCity = 'allcities' } = useGlobalData();
  const { locale } = useRouter();

  const {
    cityId,
    isLoading: citiesLoading,
    error: citiesError,
  } = useHomeCity();
  const filters = useMemo(
    () => ({
      city: cityId,
    }),
    [cityId],
  );
  const {
    pages, error, fetchMore, hasMore, isLoading,
  } = useAdsInfiniteListQuery({
    pageSize: 12,
    filters,
    disabled: homeCity !== 'allcities' && !filters.city,
  });

  const infiniteListRef = useInfiniteListRef(hasMore, fetchMore, isLoading);

  if (citiesLoading) return <Loadingmain />;
  if (error || citiesError) return <div>Error loading data</div>;

  return (
    <>
      <NextSeo
        title={i18n.mainPage.seo[locale]}
        description={i18n.mainPage.seodescription[locale]}
      />
      <h2 className={s.for_you}>{i18n.mainPage.forYou[locale]}</h2>
      <section className={s.adv_list}>
        {pages.map((page, i) => (
          <Fragment key={i}>
            {page.data.map((product) => (
              <AdLink ad={product} key={product.id}>
                <AdCard ad={product} />
              </AdLink>
            ))}
          </Fragment>
        ))}
      </section>
      <div ref={infiniteListRef} />
      {isLoading && <Loadingmain />}
    </>
  );
}
