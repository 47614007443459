import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import Slider from 'react-slick';
import Link from 'next/link';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import s from '@/styles/partials/MainBanner.module.scss';
import { api } from '@/modules/shared/api';
import { shuffle } from 'lodash';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${s.adv_images_right}`} onClick={onClick}>
      next
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${s.adv_images_left}`} onClick={onClick}>
      prev
    </div>
  );
}

export default function MainBanner() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    api
      .get(`${API_URL}/api/banners/list/`)
      .then((response) => setBanners(shuffle(response.data)))
      .catch((error) => console.error('Ошибка при загрузке баннеров', error));
  }, [setBanners]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();

  const handleBeforeChange = useCallback((oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  }, [setCurrentSlide]);

  const goToSlide = useCallback((index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  }, []);

  const settings = useMemo(() => ({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: true,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: handleBeforeChange,
  }), [
    handleBeforeChange,
  ]);

  return (
    <section className={s.banner_container}>
      <Slider {...settings} ref={sliderRef}>
        {banners.map((img) => (
          <Link key={img.id} target="__blank" href={img.banner_url}>
            <img
              style={{ objectFit: 'contain' }}
              src={img.banner_image}
              alt="Banner"
              className={s.img_banner}
            />
          </Link>
        ))}
      </Slider>

      <div className={s.adv_images_option}>
        {banners.map((img, index) => (
          <img
            key={img.id}
            src={
              index === currentSlide
                ? '/assets/icons/img_selected.svg'
                : '/assets/icons/img_option.svg'
            }
            alt="dot"
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </section>
  );
}
